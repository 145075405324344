import { Box, BoxProps, Text, TextProps } from "@chakra-ui/react"

export const FlexDivider = ({
  orientation,
  label,
  labelProps,
  color = "gray.100",
  ...boxProps
}: {
  orientation: "vertical" | "horizontal"
  label?: string | JSX.Element
  labelProps?: TextProps
  color?: BoxProps["bg"]
} & BoxProps) => {
  const props = orientation === "vertical" ? { px: 1, py: 1 } : { py: 1 }

  return (
    <Box {...props} alignSelf="stretch" position="relative" {...boxProps}>
      <Box
        bg={color}
        {...(orientation === "vertical"
          ? { w: "1px", h: "100%" }
          : { h: "1px", w: "100%" })}
      ></Box>
      {label && (
        <Text
          fontSize="xs"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bg="white"
          px="3"
          {...labelProps}
        >
          {label}
        </Text>
      )}
    </Box>
  )
}
